import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Mailto from 'reactv16-mailto'
import Layout from '../components/Layout'
import { Helmet } from 'react-helmet'

import { Section, Container, Columns, Heading, Card } from 'react-bulma-components';

interface PrivacyPageTemplateProps {
  title: string;
  email: string;
  emailSubject: string;
  emailBody: string;
  content: string;
}

export const PrivacyPageTemplate = ({ title, content, email, emailSubject, emailBody }: PrivacyPageTemplateProps) => {

  const headers = {
      subject: emailSubject,
      body: emailBody + '\r\n\r\n',
  };

  return (
    <React.Fragment>
    <Helmet>
        <title>Contact | Model Broker</title>
    </Helmet>
      <Section size="small">
        <Container>
          <Columns>
            <Columns.Column style={{textAlign: 'center'}}>
              <Heading>
                {title}
              </Heading>
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
      <Section className="second-section">
        <Container>
        <Columns>
            <Columns.Column size={8} offset={2}>
              <Card>
                <Card.Content style={{textAlign: 'justify'}}>
                    <div dangerouslySetInnerHTML={{__html: content}} />
                    <br />
                    <Mailto
                        obfuscate={true}
                        email={email}
                        headers={headers}
                    >
                        {email}
                    </Mailto>
                </Card.Content>
              </Card>
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
    </React.Fragment>
  )
}

const PrivacyPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout isIndex={false}>
      <PrivacyPageTemplate
        title={post.frontmatter.title}
        email={post.frontmatter.email}
        emailSubject={post.frontmatter.emailSubject}
        emailBody={post.frontmatter.emailBody}
        content={post.html}
      />
    </Layout>
  )
}

PrivacyPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PrivacyPage

export const PrivacyPageQuery = graphql`
  query PrivacyPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        email
        emailSubject
        emailBody
      }
      html
    }
  }
`
